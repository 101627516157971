jQuery(function ($) {

    $(document).ready(function () {
        $(document).ready(function () {
            $('.hero .hero-carousel').each(function () {
                var $this = $(this);
                $this.slick({
                    dots: true,
                    infinite: false,
                    slidesToShow: 1,
                    autoplaySpeed: 3000,
                    autoplay: true,
                    prevArrow: $(this).parents('.hero-card-wrapper').find('.slick-prev'),
                    nextArrow: $(this).parents('.hero-card-wrapper').find('.slick-next'),
                    appendDots: $(this).parents('.hero-card-wrapper').find('.hero-navigation-dots'),
                });

                $this.on('init', function (event, slick, currentSlide, nextSlide) {
                    $this.find('.slick-slide[aria-hidden="false"] a').attr('tabindex', -1);
                    $this.find('.slick-slide[aria-hidden="true"] a').attr('tabindex', 0);
                });
                $this.on('afterChange', function (event, slick, currentSlide, nextSlide) {
                    $this.find('.slick-slide[aria-hidden="false"] a').attr('tabindex', -1);
                    $this.find('.slick-slide[aria-hidden="true"] a').attr('tabindex', 0);

                    if(($this.slideCount - 1) == currentSlide){
                    setTimeout(function(){
                        $this.goTo(0);
                    }, $this.getOption('autoplaySpeed'));
                    }
                });

            });
        });
    });
});